import React from "react";
import { Helmet } from "react-helmet";

const title =
  "Advice from a third year to a fresher | Articles | Manchester Students Group";
const description = "";

export default function Blog5(p) {
  return (
    <main className="blog-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <div className="blog-post">
        <img src="/advice.jpg" alt="" />
        <h1>ADVICE FROM A THIRD YEAR TO A FRESHER</h1>
        <p>Molly Sword / Guide / 5 minute read</p>
        <p>
          Going to Uni and starting a new chapter of your life can be both an
          incredibly daunting and exciting experience. Through my last 2 years
          at Manchester, I have learnt (sometimes the hard way) some tips and
          tricks that will make adjusting to this change a lot easier so thought
          I would share some ideas of what I wished I had known as a fresher.
        </p>

        <h2>STUDY TIPS:</h2>
        <p>
          So, let’s get the boring bit out the way first! As a fresher I had
          absolutely no idea what I was in for, the thought of referencing
          seemed impossible and I didn’t know where to start with collecting
          info, researching, revising etc.
        </p>
        <p>
          Work smart, not hard. It’s important to remember to feel free to ask
          for help, your tutors are there to guide you and most likely others
          won’t know what they are doing either. I would recommend using
          programmes such as ‘cite me’ or ‘cite this for me’ which make it
          significantly easier to collect your references together. Make sure
          you update as you go too - there’s nothing worse than shutting down
          your computer, losing all your tabs and realising you’ve forgotten to
          reference.
        </p>
        <p>
          Don’t think you need to go out and buy every textbook on the syllabus
          - a lot of the time your library will provide you with resources. In
          terms of online resources, the increased use of AI has helped many
          students but be careful when using it, if the questions are similar
          for everyone in your lecture, then it is likely a lot will use AI and
          you may be accused of plagiarism. Instead, use AI as a guide to help
          you answer specific questions surrounding your topic alongside your
          other resources.
        </p>
        <p>
          My number one tip for making essays and revision so much easier is to
          type out all your notes into a document (make sure to back it up) and
          then convert it to a PDF. This will allow you to search for keywords
          across your notes and save hours of time when searching for a specific
          topic/module.
        </p>

        <h2>UNI LIFE:</h2>
        <p>
          Now the fun part! You are at university to get a degree, but first
          year is also a time to make friends and engage with new sports,
          activities or hobbies. Societies at uni are a great way to make
          friends and find people of similar interests - something which can be
          especially important if you don’t immediately get along with your
          flatmates. There are thousands of people at university and just
          because the 10 people in your accommodation aren’t your best friends
          it doesn’t mean you won’t meet your “kind” of people.
        </p>
        <p>
          As for societies, there is such a broad range of interests you can get
          involved with and it doesn’t matter if you don’t know anyone or
          haven’t done it before, Uni is a great time for new experiences and
          developing new skills so get involved if there is something that
          catches your eye! Don’t worry if it’s all too overwhelming in the
          first month and there is too much information - you can join a society
          whenever you want.
        </p>

        <h2>THE CITY:</h2>
        <p>
          Manchester is an amazing city, perfect for drinks, coffee shops, and
          clubbing. Make sure to make the most of it - I was guilty of only
          going to the same 5 places my first semester. There’s a variety of
          places such as quirky bars in the Northern quarter, cute coffee shops
          in Didsbury, nice cocktail bars in Spinningfields and fun nights out
          in Deansgate. It is worth following some Instagram or Tiktok pages who
          advertise new places to go, and if you know a good spot, that means
          you can recommend it to friends/ a date!
        </p>
        <p>
          Surviving freshers week is a universal experience and beware the
          subsequent freshers flu - make sure to stock up on some medication as
          well as a bit of vitamin C and try to get some vegetables in between
          the alcohol and take away meals, trust me you will thank yourself for
          it by October! Whilst going out at uni can be incredibly fun, make
          sure to look after yourself so you don’t get overwhelmed, if you have
          a lot of work to do, ignore the peer pressure to go out. Not going on
          one night is not going to stop you from missing out - you’ve got the
          next 3 years to do that and there is nothing wrong with having a chill
          one.
        </p>

        <h2>MONEY:</h2>
        <p>
          The most common concern for university students is running out of
          money but there are ways you can make it easier for yourself to save.
          One tip is to get a new savings account which you can put your loan
          into and transfer weekly amounts into. This will save you from buying
          everyone you meet in the club a drink and waking up with a nasty
          surprise from your bank in the morning! This also works well with apps
          like monzo - you can create money pots such as rent, food, going out
          etc to manage your money so you are not panicking by the time it gets
          to November. Most banks will also give you a nice sign-up offer too.
        </p>
        <p>
          Meal planning and going to shops such as Aldi and Lidl and having a
          supermarket card becomes a lifesaver when it comes to a food shop. I
          have been guilty of grabbing whatever looks nice and then not being
          able to make a meal out of it and end up spending more than needed. It
          is also worth cooking in bulk and buying some freezer containers is
          great future planning for when you are too tired/hungover to cook,
          saving the money you would spend on a takeaway.
        </p>
        <p>
          Getting an NUS card or Unidays is a great way to go out for food or
          get large discounts on clothes when at uni - make the most of it while
          you are a student! It is also worth checking midweek deals in local
          bars or restaurants - almost every place will have one.
        </p>

        <h2>MAKING A ROOM A HOME:</h2>
        <p>
          Your environment is super important to your mental state at uni. I
          would be a hypocrite if I told you not to pack too much considering my
          first-year room was overspilling with things I didn’t need, however it
          is important to remember you don’t have as much storage as you may be
          used to and you don’t want your space to feel too crammed. Get rid of
          everything you don’t need!
        </p>
        <p>
          I would also recommend buying storage containers that can go under
          your bed or around your room to allow you to feel more organised. A
          drying rack for your kitchen is a great investment to make the space
          feel more organised and less cluttered, and remember - you do not need
          10 sets of knives and forks and 5 plates, it’s just more washing up to
          do/more spaces for mould to build up if you forget!
        </p>
        <p>
          When decorating your room, it is worth getting some sticky command
          strips, so you don’t damage the walls and end up risking your deposit.
          A clean and organised space can make a huge difference to how you feel
          and help you not get overwhelmed.
        </p>

        <p>
          Overall, there is a large amount of information to process when moving
          to university and whilst this may all seem daunting it is important to
          remember that everyone else is in the same position - so make sure to
          look after yourself, ask for help, and have fun!
        </p>
      </div>
    </main>
  );
}
